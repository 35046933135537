@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;

.header-buttons-language-and-search {
    display: inline-flex !important;

    &:hover {
        background-color: transparent !important;
    }

    &.icon-background svg {
        background-color: ddsTokens.$dds-color-surface-selected-default;
        border-radius: 50%;
        padding: 6px;
    }
}

.language-selection {
    display: flex;
    justify-content: center;

    .languages {
        display: flex;
        flex-direction: column;
        gap: ddsTokens.$dds-spacing-x2;
    }

    .language-link {
        font: ddsTokens.$dds-font-body-large;
        color: ddsTokens.$dds-color-text-default;
        display: flex;
        gap: ddsTokens.$dds-spacing-x0-5;
        cursor: pointer;

        text-decoration: underline;
        transition:
            0.2s,
            text-decoration-color 0.2s,
            var(--dds-focus-transition);
        text-decoration-thickness: max(1px, 0.0625rem);
        text-underline-offset: max(4px, 0.25rem);

        &:hover,
        &.active {
            text-decoration-color: transparent;
        }

        &:focus-visible {
            outline: var(--dds-focus-outline);
            outline-offset: var(--dds-focus-outline-offset);
            box-shadow: var(--dds-focus-box-shadow);
        }
    }
}

#tu-header .language-selection .language-link {
    color: ddsTokens.$dds-color-text-on-inverse;

    &:hover,
    &.active {
        border-color: transparent;
    }
}
