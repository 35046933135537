@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;
@use '../../../../styles/css_constant' as *;

$top_menu_max_height_mobile: 82px;
$top_menu_full_width_height: 133px;

.domstolno-header {
    border-bottom: 1px solid #151c22;
    height: $top_menu_full_width_height;
    padding-left: ddsTokens.$dds-spacing-x4;
    padding-right: ddsTokens.$dds-spacing-x4;

    @media screen and (max-width: $nettbrett_max_width) {
        height: fit-content;
        padding: 0;
    }

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 100%;
        margin: auto;
        max-width: 1400px;

        @media screen and (max-width: $nettbrett_max_width) {
            height: $top_menu_max_height_mobile;
        }

        .nav-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            height: 100%;
            padding-right: ddsTokens.$dds-spacing-x1-5;
            gap: ddsTokens.$dds-spacing-x0-5;

            @media screen and (max-width: $nettbrett_max_width) {
                justify-content: center;
                align-items: center;
                padding-right: 10px;

                ul {
                    align-items: center;

                    &.open-mobile {
                        align-items: flex-start;
                    }
                }
            }

            .header-menu-item {
                color: ddsTokens.$dds-color-text-default;
                font-size: 20px;
                padding: 12px 26px;
                text-decoration: none !important;
                display: block;
                border: none;

                span {
                    border-bottom: 1px solid transparent;
                }

                &.selected {
                    background-color: ddsTokens.$dds-color-surface-hover-default !important;
                }

                &.child-page-visited {
                    background-color: ddsTokens.$dds-color-surface-hover-default !important;
                }

                @media screen and (max-width: 1200px) {
                    padding: 12px ddsTokens.$dds-spacing-x1 !important;
                }

                @media screen and (max-width: $nettbrett_max_width) {
                    &.link-with-icon span {
                        margin-right: ddsTokens.$dds-spacing-x1;
                    }
                }

                @media screen and (max-width: $nettbrett_max_width) {
                    padding: ddsTokens.$dds-spacing-x1 0;
                    font-size: 16px !important;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: ddsTokens.$dds-spacing-x1 ddsTokens.$dds-spacing-x1 ddsTokens.$dds-spacing-x1 0 !important;

                    span {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                    }

                    &.selected {
                        background-color: #9fc5de;
                    }
                }
            }

            .menu-item-button-mobile-icon {
                display: none;

                @media screen and (max-width: $nettbrett_max_width) {
                    display: block;
                }
            }

            .nav-menu-group {
                display: none;
                column-gap: ddsTokens.$dds-spacing-x2;

                -moz-column-gap: ddsTokens.$dds-spacing-x4;
                position: absolute;
                padding: ddsTokens.$dds-spacing-x2 100vw;
                width: 100vw;
                background-color: ddsTokens.$dds-color-surface-hover-default;
                z-index: 2;
                animation: fadeIn 0.5s;
                right: 50px;
                min-height: 500px;
                box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
                border-top: 1px solid ddsTokens.$dds-color-text-default;
                max-width: 1320px;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;

                .sub-menu-column {
                    padding: 0 ddsTokens.$dds-spacing-x1;

                    h2 {
                        margin-bottom: ddsTokens.$dds-spacing-x1;
                        padding-left: ddsTokens.$dds-spacing-x1;
                    }

                    ul {
                        flex-direction: column;
                        padding: 0;

                        li {
                            border-bottom: 1px solid ddsTokens.$dds-color-brand-secondary-medium;
                            color: ddsTokens.$dds-color-surface-inverse-hover !important;
                            padding: 0;
                            display: flex;

                            &:first-child {
                                border-top: 1px solid ddsTokens.$dds-color-brand-secondary-medium;
                            }

                            .submenu-link {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 100%;
                                text-decoration: none;

                                .link-container {
                                    padding: ddsTokens.$dds-spacing-x1 ddsTokens.$dds-spacing-x3
                                        ddsTokens.$dds-spacing-x1 ddsTokens.$dds-spacing-x1;

                                    span {
                                        border-bottom: 1px solid transparent;
                                    }
                                }
                            }
                        }
                    }
                }

                @media screen and (max-width: $nettbrett_max_width) {
                    min-height: fit-content;
                    position: relative;
                    flex-direction: column;
                    right: 0;
                    box-shadow: none;
                    border-top: none;
                    margin: auto;
                    padding: ddsTokens.$dds-spacing-x3 0;
                    width: auto;

                    .sub-menu-column {
                        padding: 0;

                        h2 {
                            padding-left: 0;
                        }

                        ul {
                            flex-direction: column;

                            li {
                                border-bottom: none;

                                .submenu-link {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    margin-right: ddsTokens.$dds-spacing-x1;

                                    .link-container {
                                        padding-left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: 1300px) {
                .menu-items-container.se {
                    .header-menu-item {
                        padding: 12px;
                        font-size: 16px;
                    }
                }
            }

            @media screen and (max-width: $nettbrett_max_width) {
                .menu-items-container {
                    display: none;

                    &.se .header-menu-item {
                        padding: 12px;
                    }

                    &.open-mobile {
                        background-color: ddsTokens.$dds-color-surface-hover-default;
                        min-height: 550px;
                        height: 100vh;
                        width: calc(100% - 0px);
                        top: $top_menu_max_height_mobile;
                        border-top: 1px solid ddsTokens.$dds-color-text-default;
                        position: absolute;
                        z-index: 2;
                        left: 0;
                        flex-direction: column;
                        align-items: center;
                        display: flex !important;
                        box-sizing: border-box;
                        padding: 100px ddsTokens.$dds-spacing-x1 0 ddsTokens.$dds-spacing-x1;

                        li {
                            width: 100%;
                            border-top: 1px solid ddsTokens.$dds-color-brand-secondary-medium;
                            margin: 0 ddsTokens.$dds-spacing-x1;
                        }
                    }
                }
            }

            .selected {
                display: flex !important;
            }

            .sub-menu-column {
                flex: 1;
                min-width: 0;
            }

            .nav-icon-buttons {
                display: inline-flex !important;
                width: 120px;

                svg {
                    background-color: ddsTokens.$dds-color-surface-hover-default;
                    border-radius: 50%;
                    padding: 6px;
                }

                &:hover {
                    background-color: transparent !important;
                }
            }

            .burger-menu-container {
                display: none;

                @media screen and (max-width: $nettbrett_max_width) {
                    display: block;
                }

                button:hover {
                    background-color: transparent !important;
                }
            }

            @media screen and (max-width: $nettbrett_max_width) {
                .language-menu-container {
                    display: none;

                    &.open-mobile {
                        position: absolute;
                        margin-right: 16px;
                        top: 108px;
                        z-index: 10;
                        display: block;
                        right: ddsTokens.$dds-spacing-x0-5;
                    }

                    &.open-mobile.language-selection-open {
                        left: ddsTokens.$dds-spacing-x0-5;

                        .dropdown-menu {
                            top: -25px;
                            z-index: -1;
                            right: -36px;
                            left: -16px;
                        }

                        span {
                            text-decoration: underline !important;
                        }

                        svg {
                            background-color: transparent !important;
                        }
                    }
                }
            }
        }
    }

    .dropdown-menu {
        position: absolute;
        border-top: 1px solid #151c22;
        right: 0;
        left: 0;
        top: $top_menu_full_width_height;
        z-index: 2;
        background-color: ddsTokens.$dds-color-brand-secondary-medium;
        animation: fadeIn 0.5s;
        min-height: 500px;
        padding-top: 80px;

        @media screen and (max-width: $nettbrett_max_width) {
            height: 100vh;
            top: $top_menu_max_height_mobile;

            .language-selection {
                padding-top: ddsTokens.$dds-spacing-x2;
            }
        }
    }

    @media screen and (min-width: $nettbrett_max_width) {
        .menu-link-button-hover:hover {
            background-color: transparent !important;

            span {
                border-bottom: 1px solid ddsTokens.$dds-color-text-default !important;
                transition: 0.2s;
            }
        }
    }

    .startpage-searchbar-header {
        margin-bottom: ddsTokens.$dds-spacing-x1-5;
    }

    .search-box-margin {
        padding-top: 81px;
    }
}
