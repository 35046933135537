@use '@norges-domstoler/dds-design-tokens/dist/scss/_ddsTokens' as ddsTokens;
@use '../../../styles/css_constant' as *;

// Disable user agent stylesheet
* {
    margin: 0;
}

body {
    margin: 0;
    overflow-x: hidden;
    background-color: white;
}

.epi-page-container:not(.supremeCourt div) {
    overflow-x: hidden;
}

.mosey-layout {
    font: ddsTokens.$dds-font-body-medium;
    letter-spacing: ddsTokens.$dds-font-body-large-letter-spacing;

    ol {
        margin: 16px 0;
    }

    ul {
        margin: 16px 0;
    }

    footer {
        ul {
            margin: inherit;
        }
    }

    strong {
        font-weight: 600;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    main {
        word-break: break-word;

        .hyphenate-title {
            hyphens: manual;
        }
    }

    @media screen and (max-width: $page_width_when_hyphens_are_used) {
        main {
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            hyphens: auto;
        }
    }

    header {
        nav {
            ol li a {
                border-bottom: none !important;
                text-underline-offset: 2px;
            }

            ul {
                display: flex;
                list-style-type: none;
                margin: 0;
                padding: 0;
            }
        }
    }

    .page-type-margin-bottom {
        margin-bottom: ddsTokens.$dds-spacing-x4;
    }

    .ingress,
    .ingress-lead {
        margin-top: ddsTokens.$dds-spacing-x2;
        margin-bottom: ddsTokens.$dds-spacing-x1;
    }

    @media screen and (max-width: $nettbrett_max_width) {
        .ingress,
        .ingress-lead {
            margin-top: ddsTokens.$dds-spacing-x1-5;
            margin-bottom: ddsTokens.$dds-spacing-x1-5;
        }
    }

    .col-lg-6 {
        min-width: $block_max_width;
    }

    @media (min-width: calc($block_max_width + 80px + 1px)) {
        .col-lg-6 {
            flex: 0 0 auto !important;
            width: 50% !important;
        }
    }

    @media screen and (max-width: calc($block_max_width + 80px)) {
        .col-lg-6 {
            min-width: 0 !important;
        }
    }
}

.maincontent {
    padding-top: ddsTokens.$dds-spacing-x1-5;
}

#main {
    scroll-behavior: smooth;
}

#main:focus-visible {
    outline: none !important;
}

.maincontent {
    table {
        overflow: auto;
        display: block;
        word-break: keep-all;

        th {
            word-break: keep-all;
            white-space: nowrap;
        }

        td {
            min-width: 100px;
        }
    }
}

.standard-table {
    table {
        word-break: keep-all;

        th {
            word-break: keep-all;
            white-space: nowrap;
            font: var(--dds-font-heading-xsmall);
            background-color: ddsTokens.$dds-color-surface-hover-default !important;
        }

        td {
            min-width: 100px;
        }

        .large-cell {
            min-width: 120px;
        }
    }

    @media (min-width: $nettbrett_max_width) {
        &.block {
            margin: 0 -100vw;
            padding: 0 100vw !important;
            max-width: calc(1320px / 12 * 7);
            width: 100vw;
        }
    }
}

.xhtml-container-collapseable-list-block p:last-child {
    margin-bottom: 0 !important;
}

// Spacing above different blocks
.general-block-spacing {
    margin-top: ddsTokens.$dds-spacing-x3;
}

@media screen and (max-width: $nettbrett_max_width) {
    .general-block-spacing {
        margin-top: ddsTokens.$dds-spacing-x1-5;
    }
}

@media screen and (max-width: $nettbrett_max_width) {
    #main h1 {
        font: ddsTokens.$dds-font-heading-large;
        letter-spacing: ddsTokens.$dds-font-heading-large-letter-spacing;
        color: ddsTokens.$dds-color-text-default;
    }
}

caption.table-caption {
    font: ddsTokens.$dds-font-heading-large;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: ddsTokens.$dds-font-heading-large-letter-spacing;
    color: ddsTokens.$dds-color-text-default;
    padding-top: ddsTokens.$dds-spacing-padding-top-heading;
    margin-bottom: ddsTokens.$dds-font-heading-large-paragraph-spacing;
    margin-top: 0;
    text-align: left;
}

#main-body .xhtml-container > p:first-child {
    padding-top: ddsTokens.$dds-spacing-padding-top-heading;
}

.text-block div div > p:first-child {
    padding-top: ddsTokens.$dds-spacing-padding-top-heading;
}

.mosey-layout {
    // apply styling for links in XHTML
    .xhtml-container,
    .xhtml-container-link-styling,
    .xhtml-container-text-block,
    .xhtml-container-listoflinks-block,
    .xhtml-container-collapseable-list-block,
    .xhtml-container-teaserlist-block,
    .xhtml-container-youtube-block,
    .xhtml-container-vimeo-block,
    .xhtml-container-feedback {
        a {
            font: inherit;
            color: ddsTokens.$dds-color-text-action-resting;
            width: fit-content;
            text-decoration: underline;
            text-decoration-thickness: max(1px, 0.0625rem);
            text-underline-offset: max(4px, 0.25rem);
            margin: 0;

            &:hover {
                text-decoration-color: transparent;
            }

            &:visited {
                color: ddsTokens.$dds-color-text-action-visited;
            }

            &:focus-visible {
                outline: ddsTokens.$dds-color-focus-outside 2px solid;
                outline-offset: ddsTokens.$dds-spacing-x0-125;
                box-shadow: 0 0 0 2px ddsTokens.$dds-color-focus-inside;
                border-color: ddsTokens.$dds-color-border-default;
            }

            @media (prefers-reduced-motion: no-preference) {
                transition:
                    color 0.2s,
                    text-decoration-color 0.2s,
                    outline-offset 0.2s;
            }
        }

        .focus-internal-link:focus-visible {
            outline: ddsTokens.$dds-color-focus-outside 2px solid;
            outline-offset: ddsTokens.$dds-spacing-x0-125;
            box-shadow: 0 0 0 2px ddsTokens.$dds-color-focus-inside;
            border-color: ddsTokens.$dds-color-border-default;
        }

        .focus-internal-link:focus-visible p {
            border-bottom: none !important;
        }
    }

    .ingress,
    .maincontent,
    .xhtml-container,
    .text-block,
    .xhtml-container-collapseable-list-block,
    .xhtml-container-teaserlist-block,
    .xhtml-container-vimeo-block,
    .xhtml-container-youtube-block {
        h1 {
            font: ddsTokens.$dds-font-heading-xlarge;
            letter-spacing: ddsTokens.$dds-font-heading-xlarge-letter-spacing;
            margin-top: ddsTokens.$dds-font-heading-xlarge-paragraph-spacing;
            margin-bottom: ddsTokens.$dds-font-heading-xlarge-paragraph-spacing;
            color: ddsTokens.$dds-color-text-default;
        }

        h2 {
            font: ddsTokens.$dds-font-heading-large;
            letter-spacing: ddsTokens.$dds-font-heading-large-letter-spacing;
            color: ddsTokens.$dds-color-text-default;
            padding-top: ddsTokens.$dds-spacing-padding-top-heading;
            margin-bottom: ddsTokens.$dds-font-heading-large-paragraph-spacing;
            margin-top: 0;
        }

        h3 {
            font: ddsTokens.$dds-font-heading-medium;
            letter-spacing: ddsTokens.$dds-font-heading-medium-letter-spacing;
            color: ddsTokens.$dds-color-text-default;
            padding-top: ddsTokens.$dds-spacing-padding-top-heading;
            margin-bottom: ddsTokens.$dds-font-heading-medium-paragraph-spacing;
            margin-top: 0;
        }

        h4 {
            font: ddsTokens.$dds-font-heading-small;
            letter-spacing: ddsTokens.$dds-font-heading-small-letter-spacing;
            color: ddsTokens.$dds-color-text-default;
            padding-top: ddsTokens.$dds-spacing-padding-top-heading;
            margin-bottom: ddsTokens.$dds-font-heading-small-paragraph-spacing;
            margin-top: 0;
        }

        p {
            font: ddsTokens.$dds-font-body-medium;
            letter-spacing: ddsTokens.$dds-font-body-medium-letter-spacing;
            color: ddsTokens.$dds-color-text-default;
            margin-top: 0;
            margin-bottom: ddsTokens.$dds-font-body-medium-paragraph-spacing;
        }
    }

    .ingress-lead p {
        font: ddsTokens.$dds-font-lead-medium;
        letter-spacing: ddsTokens.$dds-font-lead-medium-letter-spacing;
        color: ddsTokens.$dds-color-text-default;
        margin-bottom: ddsTokens.$dds-font-body-large-paragraph-spacing;
        margin-top: 0;
    }

    .ingress-heading-sans p {
        font: ddsTokens.$dds-font-heading-large;
        letter-spacing: ddsTokens.$dds-font-heading-large-letter-spacing;
        color: ddsTokens.$dds-color-text-default;
    }

    .body-sans04 p {
        font: ddsTokens.$dds-font-body-large;
        letter-spacing: ddsTokens.$dds-font-body-large-letter-spacing;
        color: ddsTokens.$dds-color-text-default;
    }

    .body-sans03 p {
        font: ddsTokens.$dds-font-body-medium;
        letter-spacing: ddsTokens.$dds-font-body-medium-letter-spacing;
        color: ddsTokens.$dds-color-text-default;
    }
}

.global-messages-container {
    width: 100%;

    > div {
        margin: auto;
        width: 100%;
        max-width: 1285px;

        .message-content {
            display: inline-block;
        }
    }
}

.search-input-field {
    width: 100% !important;
}
