@use '../../../../../styles/css_constant' as *;
@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;

.mosey-layout {
    .logo-link {
        img {
            height: 100px;

            &.se {
                width: 260px;

                @media screen and (max-width: 1400px) {
                    width: 200px;
                    margin-left: -32px;
                }

                @media screen and (max-width: $nettbrett_max_width) {
                    width: 130px;
                    margin-left: ddsTokens.$dds-spacing-x1;
                }
            }

            @media screen and (max-width: $nettbrett_max_width) {
                height: ddsTokens.$dds-spacing-x3;
                margin-left: 16px;
                width: fit-content;
                display: flex;
            }

            @media screen and (max-width: 300px) {
                margin-left: 2px;
            }
        }
    }
}
