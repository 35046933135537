@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;

.mosey-layout.tilsynsutvalget {
    footer {
        background-color: ddsTokens.$dds-color-brand-secondary-default;
        min-height: 150px;

        .footer-links-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .footer-links {
                padding: ddsTokens.$dds-spacing-x2 0;
                margin-bottom: ddsTokens.$dds-spacing-x1;
                display: flex;
                flex-direction: column;

                .footer-url-color-and-margin {
                    color: ddsTokens.$dds-color-text-default;
                    margin-top: ddsTokens.$dds-spacing-x1;
                }
            }
        }

        &.footer-max-width-section {
            display: flex;
            justify-content: center;

            .footer-max-width {
                max-width: 1400px !important;
                width: 1400px;
                padding-bottom: 10px;
            }
        }
    }
}
