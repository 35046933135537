@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;
@use '../../../../styles/css_constant' as *;

$top_menu_max_height_mobile: 100px;
$top_menu_full_width_height: 133px;

.tilsynsutvalget {
    header {
        position: relative;
        height: $top_menu_full_width_height;
        border-bottom: 1px solid black;

        @media (max-width: $nettbrett_max_width) {
            height: $top_menu_max_height_mobile;
        }

        .header-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: fit-content;
            padding: 20px 30px 30px 30px;
            max-width: 1400px;
            margin: auto;

            @media (max-width: 1442px) {
                padding: 20px 30px 30px 30px;
            }

            @media (max-width: $nettbrett_max_width) {
                padding: 20px 10px 20px 10px;

                .logo img {
                    height: 50px;
                }
            }

            img {
                height: 70px;
            }

            .header-buttons {
                border: 1px solid ddsTokens.$dds-color-border-default;
                display: inline-flex;

                &.mobile {
                    display: none;
                }
            }

            #languageButton {
                margin-right: ddsTokens.$dds-spacing-x1-5;
                width: 114px;
            }

            #menuButton {
                width: 100px;
            }

            @media (max-width: 960px) {
                #languageButton {
                    display: none;
                }

                .nav-menu-group {
                    animation: none;
                }
            }

            .menu-icon-button {
                margin-left: auto;
                margin-bottom: ddsTokens.$dds-spacing-x3;
                padding: 10px 14px;

                button {
                    background-color: inherit;
                    border: none;
                    display: inline-block;
                    cursor: pointer;

                    svg {
                        vertical-align: middle;
                        padding-right: 6px;
                        color: ddsTokens.$dds-color-bg-default;
                    }

                    p {
                        display: inline-block;
                        vertical-align: middle;
                        color: ddsTokens.$dds-color-bg-default;
                        border-bottom: 1px solid ddsTokens.$dds-color-bg-default;
                    }
                }
            }

            .nav-menu-group {
                position: absolute;
                right: 0;
                left: 0;
                top: $top_menu_full_width_height;
                z-index: 2;
                background-color: ddsTokens.$dds-color-brand-primary-default;
                animation: fadeIn 0.5s;
                min-height: 500px;
                display: none;

                @media (max-width: $nettbrett_max_width) {
                    top: $top_menu_max_height_mobile;
                }

                .language-margin {
                    margin-top: 86px;

                    @media (max-width: 960px) {
                        margin-top: 20px;
                    }
                }

                &.display {
                    display: block;
                }

                .menu-container {
                    padding: ddsTokens.$dds-spacing-x4 ddsTokens.$dds-spacing-x2;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    column-gap: ddsTokens.$dds-spacing-x3;

                    a {
                        color: ddsTokens.$dds-color-bg-default;
                        font-size: 16px;
                        margin-bottom: 16px;
                        display: inline-block;
                    }

                    a.main-link {
                        font-size: 24px;
                        margin-bottom: ddsTokens.$dds-spacing-x2;
                    }

                    .tu-link-section {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: ddsTokens.$dds-spacing-x2;
                    }

                    .language-selector-button {
                        margin-left: auto;
                        margin-bottom: ddsTokens.$dds-spacing-x3;
                        padding: 10px 14px;
                    }
                }

                .language-selector-button {
                    display: none;
                }

                @media (max-width: 960px) {
                    .menu-container {
                        flex-direction: column;
                        padding: ddsTokens.$dds-spacing-x3 0;

                        .secondary-link {
                            margin-left: ddsTokens.$dds-spacing-x2;
                        }
                    }

                    .language-selector-button {
                        display: flex;
                    }
                }
            }

            button:active {
                scale: none;
            }
        }
    }
}
