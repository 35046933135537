$page_max_width: 1320px; // max width of the content

$block_max_width: calc(
    #{$page_max_width} / 2
); // we use max 6 columns for all texts, therefore blocks should never extend half of the page_max_width

$card_max_width: 490px;

$card_max_grid: 320px;

$card_grid_gap: 24px;

$page_width_when_hyphens_are_used: 300px; // we want words to jump to new line if possible, therefore not using the hyphens: auto in general. But for small screen we should use hyphens, otherwise the words will go "out of the screen"

$mobil_max_width: 480px; // must set this in HeaderMobile.ts (mobile_max_width)

$nettbrett_max_width: 1000px; // must also be in Logo.tsx
